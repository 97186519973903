@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #1b1b1b; /* Track color */
}

::-webkit-scrollbar-thumb {
  background-color: #6c6c6c; /* Scrollbar color */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid #1b1b1b; /* Optional: border around the thumb */
}

.scrollbar-thin {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #6c6c6c #1b1b1b; /* Firefox */
}
